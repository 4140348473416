export default {
  aria_bookmark: 'Mettre en favori',
  aria_unbookmark: 'Retirer le favori',
  back_to_list: 'Retour à la liste',
  body: 'Message',
  save: 'Sauvegarder',
  cancel: 'Annuler',
  clear_filters: 'Effacer les filtres',
  default_search_placeholder: 'Rechercher',
  done: 'Terminé',
  download: 'Télécharger',
  filters: 'Filtres',
  page_size: 'Afficher sur la page',
  settings: 'Paramètres',
  show_less: 'Voir moins',
  show_more: 'Voir plus',
  sort: 'Trier',
  subject: 'Sujet',
  close: 'Fermer',
  prev: 'Précédent',
  next: 'Suivant',
  page_of: 'Page {page} sur {totalPages}',
  go_to_page: 'Aller à la page {page}',
  open_burger: 'Ouvrir la navigation principale',
  close_burger: 'Fermer la navigation principale',
  kb: 'Ko',
  mb: 'Mo',
  gb: 'Go',
  or: 'ou',
  navigation: {
    aria_user_profile: 'Menu profil utilisateur',
    dashboard: 'Tableau de bord',
    profile: 'Profil',
    organizations: 'Organisations',
    logout: 'Se déconnecter',
  },
  browser_warning: {
    main_message: 'Vous utilisez une ancienne version du navigateur.',
    sub_message:
      'Certaines fonctionnalités peuvent ne pas fonctionner. Veuillez mettre à jour vers une version plus récente.',
    update: 'Mettre mon navigateur à jour',
  },
  edit_item: "Modifier l'item",
  clone_item: "Cloner l'item",
  delete_item: "Supprimer l'item",
  dismiss_alert: "Fermer l'alerte",
  search_results: 'Aucun résultat trouvé| {count} résultat trouvé | {count} résultats trouvés',
  new: 'Nouveau',
  select_all: 'Tout sélectionner',
  search_empty_state: 'Aucun résultat trouvé',
  hint: {
    close: "Fermer l'astuce",
    open: "Ouvrir l'astuce",
  },
  view: 'Voir',
  learn_more: 'En savoir plus',
};
