import { AxiosInstance, AxiosError, CancelToken } from 'axios';
import { sortBy } from 'lodash-es';
import { Dictionary } from '../../core';
import type {
  EventDto,
  ValidationError,
  TypeOrError,
  QuoteDto,
  TransactionDto,
  FormEntryDto,
  AuthenticationResult,
  PageDto,
  UploadFileModel,
  GetFormEntriesModel,
  PaginatedList,
  RoomDto,
  FileUploadDto,
  BillingInfoDto,
  PaymentGatewayPayload,
  PostFormEntryResultDto,
  GetSchedulePresentationsByScheduleIdModel,
  SchedulePresentationDto,
  SchedulePresentationForFormEntryDto,
  GetSchedulePresentationsByFormEntryIdModel,
  UserProfileDto,
  FeatureDto,
  GetReviewsModel,
  ReviewDto,
  ScheduleDto,
  MessageThreadType,
  MessageDto,
  MessageReactionType,
  JoinSessionResult,
  MessageThreadDto,
  TrackDto,
  PaymentMethod,
  GetEventsModel,
  EventListingDto,
  CreateOrganizationModel,
  CreateOrganizationResult,
} from './models';
import { toURLSearchParams, toReviewsSearchParams } from './models';
import 'url-search-params-polyfill';
import { DeleteFileModel } from './models/DeleteFileModel';
import { UploadFileResult } from './models/UploadFileResult';
import { extractFileNameFromHeaders } from '../../helpers';

type UploadFileOptions = { onUploadProgress?: (progressEvent: ProgressEvent) => void; cancelToken?: CancelToken };

export interface IFourwavesApiService {
  // account
  forgotPassword(email: string, eventId?: string): Promise<TypeOrError<boolean>>;
  resetPassword(model: any): Promise<TypeOrError<AuthenticationResult>>;

  // users
  getUserById(id: string): Promise<UserProfileDto>;

  // events
  getEvent(id: string): Promise<EventDto | null>;
  getEvents(query: GetEventsModel): Promise<PaginatedList<EventListingDto>>;
  getDashboardUrl(eventId: string): string;
  getPages(eventId: string, withHidden: boolean): Promise<PageDto[]>;
  getSessions(eventId: string): Promise<ScheduleDto[]>;
  bookmarkSession(sessionId: string): Promise<TypeOrError<boolean>>;
  unbookmarkSession(sessionId: string): Promise<TypeOrError<boolean>>;
  getRooms(eventId: string): Promise<RoomDto[]>;
  getRoom(roomId: string): Promise<RoomDto>;

  // organizations
  createOrganization(model: CreateOrganizationModel): Promise<TypeOrError<CreateOrganizationResult>>;

  // conferencing
  joinPrecall(eventId: string): Promise<TypeOrError<JoinSessionResult>>;
  joinSession(formEntryId: string, isListenMode: boolean): Promise<TypeOrError<JoinSessionResult>>;

  // features
  getFeatures(eventId: string): Promise<FeatureDto[]>;

  // forms entries
  getFormEntry(formEntryId: string, overridePublishedStatus: boolean): Promise<FormEntryDto>;
  getFormEntries(model: GetFormEntriesModel): Promise<PaginatedList<FormEntryDto>>;
  getHiddenFieldsBasedOnFormData(formId: string, formData: Dictionary<string | string[]>): Promise<string[]>;
  postFormEntry(
    formId: string,
    formEntryId: string | null,
    values: any,
    billingInfo: BillingInfoDto | null,
    timestamp: string,
    paymentMethod: PaymentMethod | null,
    paymentGatewayPayload: PaymentGatewayPayload | null,
    nameOnCard: string | null,
    reviewId?: string,
    eventId?: string,
    couponCode?: string,
  ): Promise<TypeOrError<PostFormEntryResultDto | null>>;
  contactPresenter(formEntryId: string, subject: string, body: string): Promise<TypeOrError<boolean>>;
  contactParticipant(formEntryId: string, subject: string, body: string): Promise<TypeOrError<boolean>>;

  // messages
  getThreadByToken(objectId: string, type: MessageThreadType): Promise<MessageThreadDto>;
  getThreadById(id: string): Promise<MessageThreadDto>;
  createObjectThread(
    type: MessageThreadType,
    objectId?: string,
    userIds?: string[],
  ): Promise<TypeOrError<MessageThreadDto>>;
  createDirectThread(type: MessageThreadType, userIds?: string[]): Promise<TypeOrError<MessageThreadDto>>;
  getThreadMessages(id: string, page: number, pageSize: number): Promise<PaginatedList<MessageDto>>;
  deleteThreadMessages(id: string): Promise<TypeOrError<boolean>>;
  markThreadAsRead(id: string): Promise<TypeOrError<boolean>>;
  createMessage(id: string, text: string, parentId?: string): Promise<TypeOrError<boolean>>;
  updateMessage(id: string, text: string): Promise<TypeOrError<boolean>>;
  deleteMessage(id: string): Promise<TypeOrError<boolean>>;
  upvoteMessage(id: string): Promise<TypeOrError<boolean>>;
  deleteUpvoteMessage(id: string): Promise<TypeOrError<boolean>>;
  createMessageReaction(id: string, type: MessageReactionType): Promise<TypeOrError<boolean>>;
  deleteMessageReaction(id: string, type: MessageReactionType): Promise<TypeOrError<boolean>>;

  // file upload
  getFile(fileUploadId: string): Promise<FileUploadDto>;
  getFileUploadUrl(fileUploadId: string): string;
  uploadFile(model: UploadFileModel, options?: UploadFileOptions): Promise<TypeOrError<UploadFileResult>>;
  deleteFile(model: DeleteFileModel): Promise<TypeOrError<string>>;

  // user activities
  updateUserActivity(id: string, additionalData: string): Promise<TypeOrError<boolean>>;
  emitHeartbeat(userActivityIds: string[]): Promise<TypeOrError<boolean>>;

  // accounting
  getTransactionById(id: string): Promise<TransactionDto>;
  downloadPdfReceiptById(id: string): Promise<void>;

  // presentations
  getSessionPresentationsBySessionId(
    model: GetSchedulePresentationsByScheduleIdModel,
  ): Promise<PaginatedList<SchedulePresentationDto>>;
  getSessionPresentationsByFormEntryId(
    query: GetSchedulePresentationsByFormEntryIdModel,
  ): Promise<PaginatedList<SchedulePresentationForFormEntryDto>>;

  // reviews
  getReviews(model: GetReviewsModel): Promise<PaginatedList<ReviewDto>>;

  // tracks
  getTracks(eventId: string): Promise<TrackDto[]>;
}

export class BaseApiService implements IFourwavesApiService {
  _client: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this._client = httpClient;
    if (
      !process.client &&
      this._client.defaults.baseURL?.includes('localhost') &&
      process.env.DATABASE_CONNECTION?.includes('pgsql_datastore')
    ) {
      this._client.defaults.baseURL = 'http://fourwaves_platform:8080';
    }
  }

  public async forgotPassword(email: string, returnTo?: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/account/forgot-password`, { email, returnTo });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async resetPassword(model: any): Promise<TypeOrError<AuthenticationResult>> {
    try {
      const response = await this._client.post(`/api/account/reset-password`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getUserById(id: string): Promise<UserProfileDto> {
    const response = await this._client.get<UserProfileDto>(`/api/users/${id}`);
    return response.data;
  }

  public async getEvent(id: string): Promise<EventDto | null> {
    try {
      const response = await this._client.get<EventDto>(`/api/events/${id}`);

      // Forms ordering
      if (response.data && response.data.forms) {
        response.data.forms.forEach(form => {
          form.formSections = sortBy(form.formSections, 'order');
          form.formSections.forEach(section => {
            section.formFields = sortBy(section.formFields, 'order');
            section.formFields.forEach(field => {
              field.formFieldChoices = sortBy(field.formFieldChoices, 'order');
            });
          });
        });
      }

      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async getEvents(query: GetEventsModel): Promise<PaginatedList<EventListingDto>> {
    const searchParams = new URLSearchParams(query as any);
    if (query.userRoles?.length) {
      searchParams.delete('userRoles');
      query.userRoles.forEach(role => searchParams.append('userRoles', role));
    }
    const request = `/v1.0/events?${searchParams.toString()}`;
    const response = await this._client.get<PaginatedList<EventListingDto>>(request);
    return response.data;
  }

  public async getFeatures(eventId: string): Promise<FeatureDto[]> {
    try {
      const response = await this._client.get<FeatureDto[]>(`/api/features/${eventId}`);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getPages(eventId: string, withHidden: boolean): Promise<PageDto[]> {
    const response = await this._client.get<PageDto[]>(`/api/events/${eventId}/pages?withHidden=${withHidden}`);

    // Pages and content ordering
    if (response.data) {
      response.data = sortBy(response.data, 'order');
      response.data.forEach(page => {
        if (page.contentPage && page.contentPage.contentBlocks) {
          page.contentPage.contentBlocks = sortBy(page.contentPage.contentBlocks, 'order');
        }
      });
    }

    // Filters ordering
    if (response.data && response.data) {
      response.data.forEach(page => {
        if (page.formFields) {
          page.formFields = sortBy(page.formFields, 'order');
          page.formFields.forEach(field => {
            field.formFieldChoices = sortBy(field.formFieldChoices, 'order');
          });
        }
      });
    }

    return response.data;
  }

  public async getSessions(eventId: string): Promise<ScheduleDto[]> {
    const response = await this._client.get(`/api/events/${eventId}/sessions`);
    return response.data.data;
  }

  public async bookmarkSession(sessionId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/sessions/${sessionId}/bookmark`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async unbookmarkSession(sessionId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/sessions/${sessionId}/bookmark`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getTracks(eventId: string): Promise<TrackDto[]> {
    const response = await this._client.get(`/api/events/${eventId}/tracks`);
    return response.data;
  }

  public async getRooms(eventId: string): Promise<RoomDto[]> {
    const response = await this._client.get(`/api/sessions/rooms?eventId=${eventId}`);
    return response.data.data;
  }

  public async createOrganization(model: CreateOrganizationModel): Promise<TypeOrError<CreateOrganizationResult>> {
    try {
      const response = await this._client.post<CreateOrganizationResult>(`/api/organizations`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public getDashboardUrl(eventId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/dashboard?eventId=${eventId}`;
  }

  public getForgotPasswordUrl(eventId?: string): string {
    let url = `${this._client.defaults.baseURL}/event-redirect/forgot-password`;
    if (eventId) url = `${url}?eventId=${eventId}`;
    return url;
  }

  public async getRoom(roomId: string): Promise<RoomDto> {
    const url = `/api/sessions/rooms/${roomId}`;
    const response = await this._client.get<RoomDto>(url);
    return response.data;
  }

  public async joinPrecall(eventId: string): Promise<TypeOrError<JoinSessionResult>> {
    try {
      const response = await this._client.post<JoinSessionResult>(`/api/virtual/${eventId}/precall`);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async joinSession(formEntryId: string, isListenMode: boolean): Promise<TypeOrError<JoinSessionResult>> {
    try {
      const params = new URLSearchParams();
      if (isListenMode) params.append('isListenMode', 'true');

      const request = `/api/form-entries/${formEntryId}/join-session?${params.toString()}`;
      const response = await this._client.post<JoinSessionResult>(request);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getFormEntry(formEntryId: string, overridePublishedStatus = false): Promise<FormEntryDto> {
    const query = new URLSearchParams({ overridePublishedStatus } as any);
    const response = await this._client.get<FormEntryDto>(`/api/form-entries/${formEntryId}?${query.toString()}`);
    if (response?.data?.authors) response.data.authors = sortBy(response.data.authors, author => author.order);
    return response.data;
  }

  public async getFormEntries<T = FormEntryDto>(model: GetFormEntriesModel): Promise<PaginatedList<T>> {
    const query = toURLSearchParams(model);
    const response = await this._client.get<PaginatedList<T>>(`/api/form-entries/?${query.toString()}`);
    return response.data || { items: [], totalPages: 1, totalCount: 0, count: 0, pageSize: model.pageSize };
  }

  public async getHiddenFieldsBasedOnFormData(
    formId: string,
    formData: Dictionary<string | string[]>,
  ): Promise<string[]> {
    try {
      const response = await this._client.post(
        `/api/form-entries/${formId}/conditionals`,
        { formData },
        { progress: false },
      );
      return response.data;
    } catch (error) {
      return [];
    }
  }

  public async postFormEntry(
    formId: string,
    formEntryId: string | null,
    values: any,
    billingInfo: BillingInfoDto | null,
    timestamp: string,
    paymentMethod: PaymentMethod | null,
    paymentGatewayPayload: PaymentGatewayPayload | null,
    nameOnCard: string | null,
    reviewId?: string,
    eventId?: string,
    couponCode?: string,
  ): Promise<TypeOrError<PostFormEntryResultDto | null>> {
    try {
      const response = await this._client.post<PostFormEntryResultDto>(`/api/form-entries`, {
        formId,
        values,
        billingInfo,
        timestamp,
        formEntryId,
        paymentMethod,
        paymentGatewayPayload,
        reviewId,
        eventId,
        couponCode,
        nameOnCard,
      });
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async contactPresenter(formEntryId: string, subject: string, body: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-entries/${formEntryId}/send-email`, { subject, body });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async contactParticipant(formEntryId: string, subject: string, body: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-entries/${formEntryId}/contact-participant`, { subject, body });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getThreadByToken(objectId: string, type: MessageThreadType): Promise<MessageThreadDto> {
    try {
      const response = await this._client.get<MessageThreadDto>(`/api/threads/${objectId}.${type}`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) return undefined!;
      throw error;
    }
  }

  public async getThreadById(id: string): Promise<MessageThreadDto> {
    const response = await this._client.get<MessageThreadDto>(`/api/threads/${id}`);
    return response.data;
  }

  public async createObjectThread(
    type: MessageThreadType,
    objectId?: string,
    userIds?: string[],
  ): Promise<TypeOrError<MessageThreadDto>> {
    try {
      const payload = { type, userIds: userIds ?? [], objectId };
      const response = await this._client.post<MessageThreadDto>(`/api/threads`, payload);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createDirectThread(type: MessageThreadType, userIds?: string[]): Promise<TypeOrError<MessageThreadDto>> {
    try {
      const payload = { type, userIds: userIds ?? [] };
      const response = await this._client.post<MessageThreadDto>(`/api/threads`, payload);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getThreadMessages(id: string, page: number, pageSize: number): Promise<PaginatedList<MessageDto>> {
    const params = new URLSearchParams({ page, pageSize } as any);
    const url = `/api/threads/${id}/messages?${params.toString()}`;
    const response = await this._client.get<PaginatedList<MessageDto>>(url);
    return response.data;
  }

  public async deleteThreadMessages(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete<TypeOrError<boolean>>(`/api/threads/${id}/messages`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async markThreadAsRead(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/threads/${id}/read`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createMessage(id: string, text: string, parentId?: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/threads/${id}/messages`, { parentId, text }, { progress: false });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateMessage(id: string, text: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/messages/${id}`, { text });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteMessage(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/messages/${id}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async upvoteMessage(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/messages/${id}/upvote`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteUpvoteMessage(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/messages/${id}/upvote`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createMessageReaction(id: string, type: MessageReactionType): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/messages/${id}/reaction/${type.toString()}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteMessageReaction(id: string, type: MessageReactionType): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/messages/${id}/reaction/${type.toString()}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getQuote(
    formId: string,
    formData: Dictionary<string | string[]>,
    formEntryId: string | null,
    country: string,
    province: string,
    couponCode: string,
  ): Promise<QuoteDto | null> {
    try {
      const response = await this._client.post(`/api/form-entries/${formId}/quote`, {
        formData,
        formEntryId,
        country,
        province,
        couponCode,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async getReviews(model: GetReviewsModel): Promise<PaginatedList<ReviewDto>> {
    const response = await this._client.get<PaginatedList<ReviewDto>>(
      `/api/reviews/${model.eventId}?${toReviewsSearchParams(model).toString()}`,
    );
    return response.data;
  }

  public async getFile(fileUploadId: string): Promise<FileUploadDto> {
    const response = await this._client.get<FileUploadDto>(`/api/files/${fileUploadId}`);
    return response.data;
  }

  public getFileUploadUrl(fileUploadId: string): string {
    return `${this._client.defaults.baseURL}/api/files/serve/${fileUploadId}`;
  }

  public async uploadFile(
    model: UploadFileModel,
    { onUploadProgress, cancelToken }: UploadFileOptions = {},
  ): Promise<TypeOrError<UploadFileResult>> {
    const formData = new FormData();
    formData.append('id', model.id);
    formData.append('file', model.file);
    formData.append('type', model.type);

    try {
      const headers = { 'Content-Type': 'multipart/form-data' };

      const response = await this._client.post<UploadFileResult>(`/api/files`, formData, {
        headers,
        cancelToken,
        onUploadProgress,
        progress: !onUploadProgress,
      });

      return response.data;
    } catch (error) {
      return this.apiError<UploadFileResult>(error as AxiosError);
    }
  }

  public async deleteFile(model: DeleteFileModel): Promise<TypeOrError<string>> {
    try {
      const response = await this._client.delete(`/api/files`, {
        data: model,
      });
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateUserActivity(id: string, additionalData: string): Promise<TypeOrError<boolean>> {
    try {
      const payload = { additionalData: JSON.stringify(additionalData) };
      await this._client.put(`/api/user-activities/${id}`, payload, { progress: false });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async emitHeartbeat(userActivityIds: string[]): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/user-activities/heartbeats`, { userActivityIds }, { progress: false });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getTransactionById(id: string): Promise<TransactionDto> {
    const response = await this._client.get<TransactionDto>(`/api/accounting/transactions/${id}`);
    return response.data;
  }

  public async downloadPdfReceiptById(id: string): Promise<void> {
    try {
      const response = await this._client.get(`/api/accounting/transactions/${id}/pdf`, { responseType: 'blob' });
      const filename = extractFileNameFromHeaders(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async getSessionPresentationsBySessionId(
    model: GetSchedulePresentationsByScheduleIdModel,
  ): Promise<PaginatedList<SchedulePresentationDto>> {
    const query = new URLSearchParams(model as any);
    const url = `/api/session-presentations?${query.toString()}`;
    const response = await this._client.get<PaginatedList<SchedulePresentationDto>>(url);
    return response.data;
  }

  public async getSessionPresentationsByFormEntryId(
    query: GetSchedulePresentationsByFormEntryIdModel,
  ): Promise<PaginatedList<SchedulePresentationForFormEntryDto>> {
    const request = `/api/session-presentations?${new URLSearchParams(query as any).toString()}`;
    const response = await this._client.get<PaginatedList<SchedulePresentationForFormEntryDto>>(request);
    return response.data;
  }

  protected apiError<T>(error: AxiosError): TypeOrError<T> {
    if (error.response && error.response.data) {
      return (<ValidationError>error.response.data) as TypeOrError<T>;
    } else {
      return new Error() as TypeOrError<T>;
    }
  }
}
