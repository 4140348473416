
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator';
import { uniq } from 'lodash-es';
import {
  FormEntryDto,
  FormDto,
  PageType,
  PaginatedList,
  SchedulePresentationForFormEntryDto,
  RoomDto,
  AuthorDto,
  FormEntryStatus,
} from '../../../services';
import { Color, PresentationMedia } from '../../../types';
import PresentationQnA from '../../messaging/PresentationQnA.vue';
import RawHtml from '../../layout/RawHtml.vue';
import PresentationDetailsScheduleList from './PresentationDetailsScheduleList.vue';
import PresentationDetailsOtherInformation from './PresentationDetailsOtherInformation.vue';
import Badge from '../../Badge.vue';

@Component({
  components: {
    PresentationQnA,
    PresentationDetailsScheduleList,
    PresentationDetailsOtherInformation,
    RawHtml,
    Badge,
  },
})
export default class PresentationDetails extends Vue {
  @Prop({ required: true }) readonly form!: FormDto | null;
  @Prop({ required: true }) readonly presentation!: FormEntryDto;
  @Prop({ required: true }) readonly presentationMedia!: PresentationMedia;
  @Prop({ required: true }) readonly presentationTitle!: string | null;
  @Prop({ required: true }) readonly pageType!: PageType;
  @Prop({ required: true }) readonly allRooms!: RoomDto[] | null;
  @Prop({ required: true }) readonly allSchedulePresentations!: PaginatedList<SchedulePresentationForFormEntryDto>;
  @Prop({ type: String, default: '' }) readonly focusMessageId!: string;
  @Prop({ required: true }) posterIndexRouteName!: string;
  @Prop(Boolean) readonly isLiveApp!: boolean;
  @Prop(Boolean) readonly isOrganizer!: boolean;
  @Prop(Boolean) readonly isParticipant!: boolean;
  @Prop(Boolean) readonly isVotingEnabled!: boolean;
  @Prop(Boolean) readonly isAllowedToVirtualCall!: boolean;
  @Prop(Boolean) readonly arePresentationsVisible!: boolean;

  readonly FormEntryStatus = FormEntryStatus;
  readonly Color = Color;

  affiliations: string[] = [];

  get showScheduleList() {
    return (
      this.arePresentationsVisible &&
      this.allRooms &&
      this.allRooms.length &&
      this.allSchedulePresentations &&
      this.allSchedulePresentations.count
    );
  }

  get headerImage() {
    if (!this.presentationMedia.poster && !this.presentationMedia.figures) return null;
    const url = this.presentationMedia.poster?.value || this.presentationMedia.figures?.value[0].url;
    return url ? `${url.replace(/\?.+$/, '')}?auto=format&fit=max&w=600&h=600` : null;
  }

  get headerImageLink() {
    const mediaPath = this.presentationMedia.poster ? 'poster' : 'figures';

    return this.localePath({
      name: `${this.posterIndexRouteName}-id-${mediaPath}`,
      params: { id: this.$route.params.id },
    });
  }

  get htmlContent() {
    return this.form ? this.getRteContent(this.getAbstract(this.presentation, this.form)) : '';
  }

  @Emit() messageCountUpdate(_count: number) {}
  @Emit() openContactPresenterModal() {}
  @Emit() scrollToComments() {}
  @Emit() toggleBookmark() {}
  @Emit() toggleUpvote() {}

  created() {
    if (!this.presentation?.authors || !this.form) return;
    this.affiliations = uniq(this.presentation.authors.flatMap(({ affiliations }) => affiliations));
  }

  public getAuthorSupContent(affIndex: number, author: AuthorDto, affiliation: string): string {
    return affIndex < author.affiliations.length - 1
      ? `${this.getAffiliationIndex(affiliation)},`
      : String(this.getAffiliationIndex(affiliation));
  }

  public getAffiliationIndex(affiliation: string): number {
    return this.affiliations.indexOf(affiliation) + 1;
  }

  public getAffiliationFirstWord(affiliation: string): string {
    return affiliation.split(' ')[0];
  }

  public getAffiliationRest(affiliation: string): string {
    return affiliation.split(' ').slice(1).join(' ');
  }
}
