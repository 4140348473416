export default {
  aria_bookmark: 'Bookmark',
  aria_unbookmark: 'Remove bookmark',
  back_to_list: 'Back to list',
  body: 'Message',
  save: 'Save changes',
  cancel: 'Cancel',
  clear_filters: 'Clear filters',
  default_search_placeholder: 'Search',
  done: 'Done',
  download: 'Download',
  filters: 'Filters',
  page_size: 'Show on page',
  settings: 'Settings',
  show_less: 'Show less',
  show_more: 'Show more',
  sort: 'Sort',
  subject: 'Subject',
  close: 'Close',
  prev: 'Previous',
  next: 'Next',
  page_of: 'Page {page} of {totalPages}',
  go_to_page: 'Go to page {page}',
  open_burger: 'Open main navigation',
  close_burger: 'Close main navigation',
  kb: 'KB',
  mb: 'MB',
  gb: 'GB',
  or: 'or',
  navigation: {
    aria_user_profile: 'User profile menu',
    dashboard: 'Dashboard',
    profile: 'Profile',
    organizations: 'Organizations',
    logout: 'Log out',
  },
  browser_warning: {
    main_message: `You're using an older browser version.`,
    sub_message: 'Some functionalities might not work properly. Please update to a newer version.',
    update: 'Update my browser',
  },
  edit_item: 'Edit item',
  clone_item: 'Clone item',
  delete_item: 'Delete item',
  dismiss_alert: 'Dismiss alert',
  search_results: 'No results found | {count} result found | {count} results found',
  new: 'New',
  select_all: 'Select all',
  search_empty_state: 'No results',
  hint: {
    close: 'Close hint',
    open: 'Open hint',
  },
  view: 'View',
  learn_more: 'Learn more',
};
