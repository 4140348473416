export default {
  save: 'Enregistrer',
  cancel: 'Annuler',
  next: 'Suivant',
  prev: 'Précédent',
  step: 'Étape',
  delete: {
    title: 'Supprimer {item}',
    notice: 'Êtes-vous certain de vouloir supprimer {item}?',
    cascade_notice: 'Ceci va également entraîner {cascade}. Cette action est permanente et ne peut être annulée.',
    input_label: 'Taper SUPPRIMER pour confirmer la suppression',
    action: 'Supprimer',
  },
  beforeUnload: {
    title: 'Modifications non enregistrées',
    notice: 'Êtes-vous sûr de vouloir quitter cette page ? Les modifications seront perdues.',
    confirm: 'Quitter la page',
    cancel: 'Annuler',
  },
  saving: 'En traitement...',
  deleting: 'Suppression...',
  removing: 'Retrait...',
  confirming: 'Confirmation...',
  sending: 'Envoi...',
  adding: 'Ajout...',
  downloading: 'Téléchargement...',
};
