export default {
  credit_card: {
    number: 'Card number',
    name_on_card: 'Name on card',
  },
  billing_info: {
    title: 'Billing Information',
    fullname: 'Name / Company',
    address_line_1: 'Street Address',
    city: 'City',
    postal_code: 'Postal / Zip',
    use_org: 'Use organization address',
  },
};
